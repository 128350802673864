<template>
	<no-auth>
		<div class="friend-welcome-message">
			<el-card>
				<div class="search">
					<div style="padding-right: 150px;position: relative;">
						<span style="font-weight: bold;padding-right: 4px;color: #606266;font-size: 14px;">关键字:</span>
						<el-input v-model="keywords" placeholder="欢迎语内容"
							style="width: 200px;margin-right: 10px;margin-bottom: 10px;"></el-input>

						<el-button type="primary" style="margin-right: 10px;margin-bottom: 10px;"
							@click="pageIndex=1;getList()">查询</el-button>
						<buttonPermissions :datas="'friendWelcomeAdd'">
							<el-button type="primary" style="margin-right: 10px;margin-bottom: 10px;" @click="handleEdit()">
								添加欢迎语
							</el-button>
						</buttonPermissions>
						
						<div style="position: absolute;right: 20px;top: 0px;">
							<experience-code docUrl="https://jusnn6k8al.feishu.cn/docs/doccnGD1pZPfWW1Ml85ryH1EYrd"></experience-code>
						</div>
					</div>

					<el-table :data="list" ref="multipleTable" style="width: 100%" v-loading="tableLoading">
						<el-table-column prop="Welcome" label="欢迎语">
							<template slot-scope="scope">
								<div class="welcome-view">
									<div class="welcome-text">{{scope.row.Welcome}}</div>
									<div class="welcome-tag">{{scope.row.AttachmentTypeValue}}</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="CanUseEmployeeList" label="可使用员工">
							<template slot-scope="scope">
								<div class="employee-list">
									<div class="employee-item" v-for="(item, index) in scope.row.CanUseEmployeeList" :key="index"
										v-if="index<2">
										<img class="e-hd" v-if="item.Type===3 && item.HeadUrl"
											:src="item.HeadUrl"></img>
										<span class="e-no-hd" v-if="item.Type===3 && !item.HeadUrl">员</span>
										<i class="el-icon-s-shop e-shop" v-if="item.Type!==3"
											style="color: #409eff;font-size: 20px;vertical-align: -2px;"></i>
										{{item.Name}}
									</div>
									<div class="employee-item" v-if="scope.row.CanUseEmployeeList.length > 2">
										<!-- <span class="e-no-hd">员</span> -->
										...
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="AddTime" label="创建时间">
						</el-table-column>
						<el-table-column prop="control" label="操作" width="140">
							<template slot-scope="scope">
								<buttonPermissions :datas="'friendWelcomeEdit'">
									<el-button type="text" @click="handleEdit(scope.row.Id)" style="margin-right: 10px;">编辑</el-button>
								</buttonPermissions>
								<buttonPermissions :datas="'friendWelcomeDelete'">
									<el-button type="text" style="color: #F56C6C;" @click="handleDelete(scope.row)">删除
									</el-button>
								</buttonPermissions>
							</template>
						</el-table-column>
					</el-table>
					<div class="pagination">
						<div class="left" style="margin-left: 15px;">
						</div>
						<el-pagination :page-size="pageSize" :total="total" :current-page="pageIndex"
							:page-sizes="[5, 10, 15, 20, 30, 40]" @size-change="sizeChange"
							@current-change="currentChange" layout="total, sizes, prev, pager, next, jumper">
						</el-pagination>
					</div>

					
				</div>
			</el-card>
		</div>
	</no-auth>
</template>

<script>
	import {
		qyWeixinFriendWelcomelist,
		qyWeixinFriendWelcomedelete
	} from '@/api/sv1.0.0.js';

	import noAuth from './noAuth.vue';
	import buttonPermissions from '@/components/buttonPermissions';
	import experienceCode from '@/components/experienceCode';

	import config from '@/config/index'

	export default {
		components: {
			noAuth,
			buttonPermissions,
			experienceCode
		},
		data() {
			return {
				imgApi: config.UPLOAD_IMG,

				keywords: '',
				tableLoading: false,
				pageIndex: 1,
				pageSize: 20,
				total: 0,
				list: [],
			}
		},
		created() {},
		beforeMount() {},
		mounted() {
			this.getList();
		},
		methods: {
			async getList() {
				this.tableLoading = true;
				try {
					const res = await qyWeixinFriendWelcomelist({
						Skip: (this.pageIndex - 1) * this.pageSize,
						Take: this.pageSize,
						Keywords: this.keywords
					})

					if (res.IsSuccess) {
						this.list = res.Result.Results;
						this.total = res.Result.Total;
					}
				} catch (e) {} finally {
					this.tableLoading = false;
				}
			},
			sizeChange(value) {
				this.pageSize = value;
				this.getList();
			},
			currentChange(index) {
				this.pageIndex = index;
				this.getList()
			},
			handleEdit(id) {
				
				// if (process.env.NODE_ENV == 'development'){
					this.$router.push({
						name: 'friendWelcomeMessageEdit',
						query: {
							id: id || ''
						}
					})
				// }else{
				// 	var url = location.origin + location.pathname + '#/material/friendWelcomeMessage/edit?id='+(id || '');
				// 	window.open(url)
				// }
				
			},
			handleDelete(record) {
				this.$confirm('删除后，员工与客户成功添加好友不再继续推送这条欢迎语。是否确认继续删除？', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(() => {
					this.sureDelete(record.Id)
				}).catch(() => {
				});
			},
			async sureDelete(id){
				try{
					const res = await qyWeixinFriendWelcomedelete({Id: id})
					
					this.$message.success('删除成功');
					
					this.getList();
				}catch(e){
					//TODO handle the exception
				}
			}

		}
	}
</script>

<style lang="less" scoped>
	.friend-welcome-message {
		
		.search {
			position: relative;
			margin-bottom: 15px;

			.help {
				position: absolute;
				right: 0;
				top: 5px;
				white-space: nowrap;
				color: #666;
				cursor: pointer;
			}
		}

		.employee-list {
			margin-left: 10px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.employee-item {
				display: flex;
				align-items: center;
				height: 26px;
				margin-right: 10px;
				margin-bottom: 5px;
				margin-top: 5px;
				padding: 0 5px;
				font-size: 12px;
				border: 1px solid #ddd;
				border-radius: 3px;
				white-space: nowrap;

				.e-hd {
					width: 18px;
					height: 18px;
					overflow: hidden;
					border-radius: 2px;
					margin-right: 5px;
					background-color: #F6F6F6;
				}

				.e-no-hd {
					display: inline-block;
					width: 18px;
					height: 18px;
					line-height: 18px;
					text-align: center;
					font-size: 12px;
					color: #fff;
					border-radius: 2px;
					vertical-align: 1px;
					background-color: #409eff;
					margin-right: 5px;
				}

			}
		}
		
		.pagination {
			display: flex;
			margin-top: 15px;
			justify-content: space-between;
			align-items: center;
		}
		
		.welcome-view{
			display: inline-flex;
			max-width: 100%;
			overflow: auto;
			
			.welcome-text{
				flex: 1 1 auto;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.welcome-tag{
				flex: 0 0 auto;
			}
		}
	}
</style>
